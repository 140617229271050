<template>
  <b-overlay :show="unitLoad">
  <b-container fluid>
       <b-row style="line-height: 20px">
            <template>
                <b-row>
                    <table class="table table-bordered">
                            <tr><th>{{ $t('tradeTariffApp.exporter_country')}}</th><td>: {{ getCountryName(items.exporter_country) }} </td></tr>
                            <tr><th>{{ $t('tradeTariffApp.destination_country')}}</th><td>:
                            <span v-for="(data,index) in items.destination_country" :key="index">{{ getCountryName(data) }}, </span>
                            </td></tr>
                            <tr><th>{{ $t('tradeTariffApp.date')}}</th><td>: {{ items.date | dateFormat }} </td></tr>
                            <tr><th>{{ $t('tradeTariffApp.hs_code')}}</th><td>: {{ items.hs_code }} </td></tr>
                            <tr><th>{{ $t('tradeTariffApp.shipper')}}</th><td>: {{ items.shipper }} </td></tr>
                            <tr><th>{{ $t('tradeTariffApp.consignee')}}</th><td>: {{ items.consignee }} </td></tr>
                            <tr><th>{{ $t('tradeTariffApp.port_of_loading')}}</th><td v-if="this.portsList.length > 0">:
                                <span v-for="(data,index) in items.port_of_loading" :key="index">{{ getPortName(data) }},</span>
                            </td></tr>
                            <tr><th>{{ $t('tradeTariffApp.export_amount')}}</th><td>: {{ $n(items.export_amount) }} </td></tr>
                    </table>
                </b-row>
            </template>
    </b-row>
  </b-container>
  </b-overlay>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { tariffPortsLstApi } from '../../api/routes'
export default {
    props: ['items'],
    components: {
    },
    created () {
         this.getPortsList(this.items.exporter_country)
    },
    mounted () {
    },
    data () {
        return {
            tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
            unitLoad: true,
            ports: [],
            portsList: [],
            seaPortsList: [],
            landPortsList: [],
            airPortsList: []
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        getPortsList (countryId) {
            RestApi.getData(tradeTariffServiceBaseUrl, `${tariffPortsLstApi}/${countryId}`).then(response => {
            this.unitLoad = false
            const ports = response.data.filter(obj => obj.status === 1)
            const allPorts = ports.map((obj, index) => {
            return Object.assign({}, obj, {
                value: obj.id,
                text: this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn,
                text_en: obj.port_name_en,
                text_bn: obj.port_name_bn
            })
            })
                this.portsList = allPorts
            })
        },
        getRegionName (id) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.regionList.find(item => item.value === parseInt(id))
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            } else {
            return ''
            }
        },
        getCountryName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        },
        getPortName (id) {
            const obj = this.portsList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        }
    }
}

</script>
